/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
body {
    /* font-size: 12px;  */
    /* font-family:'Trebuchet MS', sans-serif; */
    font-family:'Inter var', sans-serif;
    /* font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; */
    /* font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; */
    /* font-family:"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif; */
  }



  /* font css */
  @font-face {
    font-family: 'Inter var';
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url("./assets/fonts/inter/Inter-roman.var.woff2?v=3.18") format("woff2");
}

@font-face {
    font-family: 'Inter var';
    font-weight: 100 900;
    font-display: swap;
    font-style: italic;
    font-named-instance: 'Italic';
    src: url("./assets/fonts/inter/Inter-italic.var.woff2?v=3.18") format("woff2");
}

body {
    font-family: 'Inter var', sans-serif;
    color: rgb(3, 3, 3);
    font-weight: 100 900;
  }

.table td, .table th {
    font-family: 'Inter var', sans-serif;
    color: rgb(3, 3, 3);
    font-weight: 400 900;
}
.datatable-body-cell {
    font-family: 'Inter var', sans-serif;
    color: rgb(3, 3, 3);
    font-weight: 400 900;
}
.datatable-row-center{
  width: fit-content !important;
}
.datatable-header-cell {
    font-family: 'Inter var', sans-serif;
    color: rgb(3, 3, 3);
    font-weight: 400 900;
}
.card-title {
    font-family: 'Inter var', sans-serif;
    color: black;
    font-weight: 100 900;
}

.tableRow {
  font-family: 'Inter var', sans-serif;
    background-color: rgb(248, 245, 245);
    /* Add any other styling properties you want */
  }
